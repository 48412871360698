<template>
  
  <v-app>   
    <v-navigation-drawer v-model="drawer" v-if="!isBig" app>
      <v-list-item-group  color="primary">
        <v-list-item v-for="tab in tabs" :key="tab.id" @click="handleNavClick(tab.route)" class="app-bar-link">
          <v-list-item-content>
            <v-list-item-title>{{ tab.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-navigation-drawer>

    <v-app-bar color="white" app elevation="1" class="app-bar-olho">
      <div v-if="!isBig" class="nav-mobile-wrapper">
        <v-app-bar-nav-icon size="2rem" v-if="!isBig" color="primary" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img contain width="50px" height="120px" src="@/assets/logo.svg" @click="$router.push('/')"></v-img>
        <div @click="$router.push('/login')">

          <span class="mr-2">login</span>
          <v-icon color="primary" size="2rem" @click="$router.push('/login')">mdi-login</v-icon>
        </div>
      </div>
      <div class="nav-desktop-wrapper" v-else>
        <div class="left-items">                 
          <div  class="app-bar-link ml-3 mr-3" v-for="tab in tabs" :key="tab.id">
            <span  @click="handleNavClick(tab.route)">{{ tab.name }}</span>
          </div>
        </div>
        <v-app-bar-nav-icon v-if="!isBig" color="primary" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <div class="logo-container" @click="scrollToSection('tela2')">
          <v-img contain class="app-bar-logo" width="120px" height="120px" @click="$router.push('/')" src="@/assets/logo.svg"></v-img>
        </div>    
        <div class="right-items">
          <v-btn color="primary" @click="$router.push('/login')" elevation="2" large rounded>Login</v-btn>
        </div>
      </div>
      </v-app-bar>
      <v-main>
          <router-view></router-view>
      </v-main>
    </v-app>
</template>

<script>
export default {
  name: 'RouterHome',
  data() {
    return {
      drawer: false,
      isActive: true,      
      tabs: [
        { id: 1, name: "Início", route: 'inicio' },
        { id: 2, name: "Impacto", route: 'impacto' },
        { id: 3, name: "Sobre", route: 'sobre' },
        { id: 4, name: "Equipes", route: 'equipes' },
        { id: 5, name: "Realização", route: 'realizacao' },
        { id: 6, name: "Apoio", route: 'apoio' },
      ]
    };
  },
  computed: {
    isMap(){
      return this.$route.path === '/mapa' ? true : false
    },
    isBig() {         
      return this.$vuetify.breakpoint.lgAndUp;
    },    
  },
  watch: {    
    isBig() {
      if (this.isBig) {
        this.drawer = false
      }
    },
    drawer() {
      if (this.isBig) {
        this.drawer = false
      }
    }
  },
  methods: {
    handleNavClick(route){
      if (this.isMap){
        this.$router.push('/')
      } else {
        this.scrollToSection(route)
      }
    },    
    scrollToSection(sectionId) {      
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        history.pushState(null, null, `#${sectionId}`);
      }
    },
  },  
};
</script>
<style>
.nav-mobile-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  width: 100%;
  img{
    height: 7rem;    
    max-height: 7rem;    
  }
}
.nav-desktop-wrapper{
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
}
.v-toolbar__content{
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}
.app-bar-link {
  text-decoration: none !important;
  font-size: 18px;
  display: flex;
  font-weight: 400;
  a{
    text-decoration: none !important;
    margin: 0px 10px;
  }
}


.router-link-exact-active {
  color: #3094cf !important;

}

.app-bar-link:hover {
  color: #74c1ee;
  cursor: pointer;  
  font-size: 18px;
  font-weight: 400;
}
.app-bar-logo {
  cursor: pointer;
  height: 8rem;
  position: relative;
  right: 160px;
}

.left-items, .right-items {
  display: flex;
  align-items: center;
}
</style>